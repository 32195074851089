<template>
  <div>
    <a-card title="资金流水详情">
      <a-button slot="extra" type="primary" ghost @click="() => { this.$router.go(-1); }"> <a-icon type="left" />返回</a-button>
      <section id="pdfDom">
        <a-spin :spinning="loading">
          <a-descriptions bordered>
            <a-descriptions-item label="创建人">
              {{ info.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ info.create_time }}
            </a-descriptions-item>

            <a-descriptions-item v-if="info.purchase_order_number" label="采购单号">
              <a @click="clickpurchaseorder">{{ info.purchase_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.void_purchase_order_number" label="作废采购单号">
              <a @click="clickvoidpurchaseorder">{{ info.void_purchase_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.purchase_return_order_number" label="采购退货单号">
              <a @click="clickpurchasereturnorder">{{ info.purchase_return_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.void_purchase_return_order_number" label="作废采购退货单号">
              <a @click="clickvoidpurchasereturnorder">{{ info.void_purchase_return_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.sales_order_number" label="销售单号" >
              <a @click="clicksalesorder">{{ info.sales_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.void_sales_order_number" label="作废销售单号">
              <a @click="clickvoidsalesorder">{{ info.void_sales_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.sales_return_order_number" label="销售退货单号">
              <a @click="clicksalesreturnorder">{{ info.sales_return_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.void_sales_return_order_number" label="作废销售退货单号">
              <a @click="clickvoidsalesreturnorder">{{ info.void_sales_return_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.payment_order_number" label="付款单据">
              <a @click="clickpaymentorder">{{ info.payment_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.void_payment_order_number" label="作废付款单据">
              <a @click="clickvoidpaymentorder">{{ info.void_payment_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.collection_order_number" label="收款单据">
              <a @click="clickcollectionorder">{{ info.collection_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.void_collection_order_number" label="作废收款单据">
              <a @click="clickvoidcollectionorder">{{ info.void_collection_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.charge_order_number" label="收支单据">
              <a @click="clickchargeorder">{{ info.charge_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.void_charge_order_number" label="作废收支单据">
              <a @click="clickvoidchargeorder">{{ info.void_charge_order_number }}</a>
            </a-descriptions-item>
            <a-descriptions-item label="结算账户名称">
              {{ info.account_name }}
            </a-descriptions-item>
            <a-descriptions-item label="结算账户编号">
              {{ info.account_number }}
            </a-descriptions-item>
            <a-descriptions-item label="流水类型">
              {{ info.type_display }}
            </a-descriptions-item>
            <a-descriptions-item label="客户名称">
              {{ info.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="变化前余额">
              {{ info.amount_before }}
            </a-descriptions-item>
            <a-descriptions-item label="金额">
              {{ info.amount_change }}
            </a-descriptions-item>
            <a-descriptions-item label="变化后余额">
              {{ info.amount_after }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
  import { inventoryFlowsDetail } from '@/api/warehouse'
  
  export default {
    data() {
      return {
        loading: false,
        info: {},
      }
    },
    created(){
      this.initData();
    },
    methods: {
      initData() {
        this.info = JSON.parse(this.$route.query.item);
        // this.loading = true;
        // inventoryFlowsDetail({ id: this.$route.query.id }).then(data => {
        //   this.info = data;
        // }).finally(() => {
        //   this.loading = false;
        // });
      },

      clicksalesorder() {
        this.$router.push({ path: '/sale/sale_record_detail', query: { id: this.info.sales_order } });
      },
      clickpurchaseorder() {
        this.$router.push({ path: '/purchasing/purchase_record_detail', query: { id: this.info.purchase_order } });
      },
      clickvoidpurchaseorder() {
        this.$router.push({ path: '/purchasing/purchase_record_detail', query: { id: this.info.void_purchase_order } });
      },
      clickpurchasereturnorder() {
        this.$router.push({ path: '/purchasing/return_record_detail', query: { id: this.info.purchase_return_order } });
      },
      clickvoidpurchasereturnorder() {
        this.$router.push({ path: '/purchasing/return_record_detail', query: { id: this.info.void_purchase_return_order } });
      },
      clickvoidsalesorder() {
        this.$router.push({ path: '/sale/sale_record_detail', query: { id: this.info.void_sales_order } });
      },
      clicksalesreturnorder() {
        this.$router.push({ path: '/sale/sale_return_detail', query: { id: this.info.sales_return_order } });
      },
      clickvoidsalesreturnorder() {
        this.$router.push({ path: '/sale/sale_return_detail', query: { id: this.info.void_sales_return_order } });
      },
      clickpaymentorder() {
        this.$router.push({ path: '/finance/payment_detail', query: { id: this.info.payment_order } });
      },
      clickvoidpaymentorder() {
        this.$router.push({ path: '/finance/payment_detail', query: { id: this.info.void_payment_order } });
      },
      clickcollectionorder() {
        this.$router.push({ path: '/finance/collection_detail', query: { id: this.info.collection_order } });
      },
      clickvoidcollectionorder() {
        this.$router.push({ path: '/finance/collection_detail', query: { id: this.info.void_collection_order } });
      },
      clickchargeorder() {
        this.$router.push({ path: '/finance/income_and_pay', query: { search: this.info.charge_order_number } });
      },
      clickvoidchargeorder() {
        this.$router.push({ path: '/finance/income_and_pay', query: { search: this.info.void_charge_order_number } });
      },

    },
    mounted() {
      this.initData();
    },
  }
</script>
<style>
</style>
